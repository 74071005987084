import React from 'react'
import { Text, Box } from 'rebass'

import PageWithLeftMenu from '../../components/PageWithLeftMenu'
import SEO from '../../components/seo'

import items from './_items'

export default () => {
  return (
    <PageWithLeftMenu title="SISTEME" items={items}>
      <SEO title="Sisteme de lucru" keywords={[`fundația ebe`]} />
      <Text mb={3}>În cadrul E.B.E. functionează urmatoarele sisteme:</Text>
      <Box ml={3}>
        <ul>
          <li>Lucrarea cu femeile</li>
          <li>S.E.B.E. (Cultul penticostal)</li>
          <li>R.E.B.E. (Cultul baptist)</li>
          <li>B.E.B.E. (Cultul creștin după Evanghelie)</li>
          <li>B.A.C.I. (Bisericile autonome)</li>
        </ul>
      </Box>
    </PageWithLeftMenu>
  )
}
